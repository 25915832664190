/* Warning: please do not statically import any dependencies here except for static assets like icons */
// import icon from './file-code-solid.svg'
// import { ReactComponent as icon } from './file-code-solid.svg'
import { EyeIcon } from '@panwds/icons'
import { hasLicense } from './shared/licensing'
import Logger from './shared/Logger';

const templateNavWithSubNav: Object = {
    navigation: [{
        key: 'workflows',
        title: 'Workflows',
        expandable: true,
        path: '/workflows',
        noRBACheck: true,
        exact: false,
        mergeNav: true,
        children: [{
            key: 'app-accel-config',
            title: 'App Acceleration',
            path: '/workflows/app-acceleration',
            noRBACheck: true,
            contentClassName: 'panwds-tw3',
            component: () => import('./AppAccelRoutes'), // any component must be dynamically import like this
            children: [{
                key: 'app-accel-config-main',
                title: 'App Acceleration Main',
                path: '/workflows/app-acceleration/main',
                noRBACheck: true,
                // no component, specified in ExampleRoutes
            }, {
                key: 'app-accel-config-panwds',
                title: 'App Acceleration PanWDS',
                path: '/workflows/app-acceleration/panwds',
                noRBACheck: true,
                // no component, specified in ExampleRoutes
            },
            {
                key: 'app-accel-config',
                title: 'App Acceleration',
                path: '/workflows/app-acceleration',
                noRBACheck: true,
                // no component, specified in ExampleRoutes
            }]
    }],
    }]
};

const simplePhase1Nav: Object = {
    navigation: [{
        key: 'appAccelWorkflows',
        title: 'App Acceleration',
        contentClassName: 'panwds-tw3',
        expandable: true,
        path: '/app-acceleration',
        noRBACheck: true,
        exact: false,
        icon: EyeIcon, // icon

        component: () => import('./AppAccelRoutes'), // any component must be dynamically import like this
    }]
};

const phase1Nav: Object = {
        navigation: [{
        key: 'appAccelWorkflows',
        title: 'Workflows',
        expandable: true,
        path: '/workflows',
        noRBACheck: true,
        exact: false,
        mergeNav: true,
        children: [{
            key: 'app-accel-config',
            title: 'App Acceleration',
            path: '/workflows/app-acceleration',
            noRBACheck: true,
            contentClassName: 'panwds-tw3',
            component: () => import('./AppAccelRoutes'), // any component must be dynamically import like this
            children: [{
                key: 'app-accel-config1',
                title: 'Configuration',
                path: '/workflows/app-acceleration',
                noRBACheck: true,
                }
            ]
        }],
    }]
}

export default function init() {
    return async function tsgLoad() {
        let template: Object | undefined = undefined;
        try {
            const useTemplate = await hasLicense();
            // template = useTemplate ? templateNavWithSubNav : undefined;
            // template = useTemplate ? simplePhase1Nav : undefined;
            
            template = useTemplate ? phase1Nav : undefined;
        }
        catch(e) {
            template = undefined;
        }

        return (template) 
    };
};