import { createContext, useState } from 'react';
import { MessageDescriptor } from 'react-intl';
import { getStrings } from '../l18n/intlTools'

import { ADEMLicenseInfoResp, AddOnInfo, AppAccelAddonLicense, AppAccelAdemInfo, 
         FawkesCertInfo, AppAccelAppDef, AppAccelSettingsCommon } from '../data/queryResultsDefinition';
import Logger, { Level } from './Logger'

export enum CfgInitState {
    unknown = 'unknown',
    uninitialized = 'uninitialized',
    inprogress = 'inprogress',
    initialized = 'initialed',
    failed = 'failed',
}

export enum AllAppModifier {
    disableAll = -1,
    resetState = -2,
}

export enum ToastAppearance {
    'clear' = 'clear',
    'info' = 'info',
    'warning' = 'warning',
    'error' = 'error',
    'success' = 'success',
}

// was put in for edit boxes but can be used for anything.
export enum InputAppearance {
    "error" = "error",
    "success" = "success",
    "default" = "default"
}

export enum PopupTypes {
    none = 'NONE',
    AdemTestingPopup = 'ADEM_TESTING_POPUP',
    AppAccelDisabledPopup = 'APP_ACCEL_DISABLE_POPUP',
    AppAccelCertConfirm = 'APP_ACCEL_CERT_CONFIRM',
}

export enum CertState {
    // These apply to global cert state only
    NO_STATE = 'NO_STATE',      // Tenant hasn't selected a cert and certs not loaded (could be 
                                // just hasn't happened yet or there was an error loading certs)
    CERT_SELECTED_NO_CERT_LIST = "CERT_SELECTED_NO_CERT_LIST",  // Tenant has selected a cert but 
                                                                // can't get cert list
    NO_CERT = 'NO_CERT_SELECTED',       // Cert List loaded but no cert selected

    // These apply to global cert state and individual cert state
    EXPIRED_CERT = 'EXPIRED_CERT',
    NORMAL = 'NORMAL',
    EXPIRING = 'EXPIRING',
    NOT_LOADED = 'NOT_LOADED_YET',
}

export enum AppState {
    disabled = 'd',         // means app accel is off and this app cannot be enabled.
    app_disabled = 'ad',    // means app accel is enabled but the app is disabled.
    app_enabled = 'ae',     // means app accel is enabled and the app is enabled.
    app_enabled_sys_disabled = 'aed',  // Error condition, something temporary has 
                                       // caused cert related stuff to be disabled.
                                       // The app is currently enabled, if the user
                                       // turns the app off, it state should be set to
                                       // 'd' and it can't be turned back on until the 
                                       // situation has changed.
}

export interface ADEMLicenseInfo extends ADEMLicenseInfoResp {
    in_grace_period: boolean        // note this is something we add to the licenseInfo
}


export interface AppAccelUiAppDef extends AppAccelAppDef {
    appState: AppState
}

export interface FawkesCertInfoUI extends FawkesCertInfo {
    certState: CertState;
}

export interface Addon_licenses {
    items: AddOnInfo[];
    includeAdem: boolean | undefined
    in_grace_period: boolean
    urlEnvModifier: string | undefined;
}

export interface AppAccelSettings {
    enabled: boolean,
    ademEnabled: boolean,
    ademPercentIndex: number,
    decryption_ca_cert_name: string,        // This matches what the user has selected but not sent to the db.
    tenantApps: AppAccelUiAppDef[],
    tenantAppDefs?: AppAccelAppDef[],
    ports?: string[],           // this is deprecated; left over from a time
                                // the user could specify ports
}

export interface NaaGlobalData {
    currentTsgId: string,
    currentSubTenId: string | undefined,
    sysConfigCapable: boolean,
    appAccelEnabled: boolean,               // This is the current enablement state
    appAccelSettings: AppAccelSettings      // This is the settings as they appear on the page. Enablement can be set to enabled here
                                            // but because it hasn't been applied yet, it may be different that appAccelEnabled.  Same with
                                            // other settings
    decryption_ca_cert_name: string,        // This is the similar to appAccelEnabled. It is the current version in the DB.
    uiInitialized: CfgInitState,
    authToken: string, 

    error: MessageDescriptor | undefined,
    phase2Error: MessageDescriptor | undefined,
    toastStyle: ToastAppearance | undefined,
    toastMsg: string | undefined,
    currentPopup: PopupTypes,

    appAcclLicenseInfo: Addon_licenses,
    ademLicenseInfo: ADEMLicenseInfo | undefined,
    tenantCerts: FawkesCertInfoUI[],
    selectedCertIdx: number,
    selectedCertName: string,
    globalCertState: CertState,
    isPanoramaManaged: boolean,
    saveCertInfoNow: boolean,

    ademAvail: CfgInitState,
    // ademBackwdCompat: boolean,
}

const NaaInitSettings: AppAccelSettings = {
   enabled: false, ademEnabled: false, ademPercentIndex: 0, decryption_ca_cert_name: '', 
   tenantApps: [], tenantAppDefs: []
}

const naaGlobalInitData: NaaGlobalData = {
    appAccelSettings: {...NaaInitSettings}, appAccelEnabled: NaaInitSettings.enabled,
    uiInitialized: CfgInitState.uninitialized, authToken: '', currentSubTenId: '', saveCertInfoNow: false,
    decryption_ca_cert_name: '', sysConfigCapable: false, selectedCertIdx: -1, selectedCertName: '',
    currentTsgId: '', toastStyle: ToastAppearance.clear, toastMsg: '', tenantCerts: [], isPanoramaManaged: false,
    ademAvail: CfgInitState.uninitialized, ademLicenseInfo: {} as ADEMLicenseInfo, globalCertState: CertState.NO_STATE,
    appAcclLicenseInfo: {} as Addon_licenses, currentPopup: PopupTypes.none, error: undefined, phase2Error: undefined,
}

export type NaaContextType = {
    naaGlobal: NaaGlobalData;
    setSystemCapable: (enabled: boolean) => void;
    setAcclSettings: (settings: AppAccelSettings, savedToZos?: boolean) => void;

    setAppInfo: (index: number) => void;
    setFeatureEnabled: (enabled: boolean) => void;
    setCertName: (certName: string | undefined, savedToZos?: boolean) => void;
    setCertInfoList: (certInfoList: FawkesCertInfo[] | undefined) => void;
    
    setInitialized: (initialized: CfgInitState) => void;
    setAuthToken: (token: string) => void;
    setError: (msg: MessageDescriptor | undefined, errorCode?: number | undefined, phase2Code?: number | string | undefined ) => void 
    setCurrentTenantInfo: (tsgId: string, subTenId: string | undefined) => void;
    setToastData: ( toastStyle: ToastAppearance | undefined, toastMsg: string | undefined, errorCode?: number | string | undefined ) => void;
    showPopup: (popupType: PopupTypes) => void;
    resetContext: () => void;
    setPanoramaManaged: (isManagedByPanorama: boolean) => void;
    setSaveCertNow: (saveCertNow: boolean) => void;

    setAdemAvail: (avail: CfgInitState) => void;
    setLicenseInfo: (appAcclLicenseInfo: Addon_licenses | undefined, ademLicenseInfo?: ADEMLicenseInfo) => void;
    setAdemData: (enabled: boolean, idx: number, backwardEnabled?: boolean) => void;   
                                                            // idx is the index in an array of percentage values
                                                            // The array is [5, 10, 15, 20] which are legal values
                                                            // for percentage.  To get from values to index 
                                                            //      idx = (percentage-5)/5;
                                                            // There are utility functions for this in shared/utilities.tsx
};

// Add environment specific parts to the URL.  Environment being prod, staging 1, or staging 2.
export const buildEnvUrl = (endpoint: string): string => {
    let data = contextSingleton.getInstance();

    let newUrl = data?.appAcclLicenseInfo?.urlEnvModifier ?? ''
    newUrl += endpoint;
    return newUrl;
}

const contextSingleton = (() => {
    let naaContext: NaaGlobalData | undefined = undefined;
    let logLevel: Level = Level.ERROR;
    return {
        getInstance: (): NaaGlobalData => {
            if (naaContext === undefined) {
                naaContext = {...naaGlobalInitData}
            }

            return naaContext;
        }
    }
})()

export const NaaGlobalContext = createContext<NaaContextType | undefined>(undefined);

interface ContextProps {
    children: React.ReactNode;
}
const strs = getStrings();

const NaaContextProvider: React.FunctionComponent<ContextProps> = ({ children }) => {
    const [globalNaaData, setNaaGlobalData] = useState<NaaGlobalData>(naaGlobalInitData);
    const [logLevel, setLogLevel] = useState(Level.ERROR)

    const logState = (data: NaaGlobalData, routine: string): void => {
        if (logLevel > Level.ERROR) {
            Logger.info(`${routine}: Context data is ${JSON.stringify(data, null, 2)}`);
        }
    }

    const setNewLogLevel = (newLevel: Level): void => {
        setLogLevel(newLevel)
    }

    const setSysCapable = (capable: boolean): void => {
        let data = contextSingleton.getInstance();
        data.sysConfigCapable = !!capable;

        logState(data, `setSysCapable`);
        setNaaGlobalData( {...data} );
    }

    const resetAppStates = (apps: AppAccelUiAppDef[], enable: boolean) => {
        let data = contextSingleton.getInstance();

        if (! enable) {
            apps.forEach((app: AppAccelUiAppDef) => {
                app.appState = AppState.disabled;
            });
        } else {
            apps.forEach((app: AppAccelUiAppDef) => {
                app.appState = app.enabled ? AppState.app_enabled : AppState.app_disabled;
            });
        }

        return apps;
    }

    const buildAppState = (data: NaaGlobalData, appDef: AppAccelAppDef): AppAccelUiAppDef => {
        const appUiDef = appDef as AppAccelUiAppDef;

        appUiDef.appState = ((! data.appAccelEnabled || data.appAcclLicenseInfo?.in_grace_period) || (data.decryption_ca_cert_name?.length === 0)) ? 
                                    AppState.disabled : 
                                    appUiDef.enabled ? AppState.app_enabled : AppState.app_disabled;

        appUiDef.appState = data.globalCertState === CertState.NO_STATE ? AppState.app_disabled : appUiDef.appState;

        return appUiDef;
    }

    const setAcclSettings = (settings: AppAccelSettings, savedToZos?: boolean ): void => {
        let data = contextSingleton.getInstance();

        let apps: AppAccelAppDef[]= settings.tenantAppDefs ? settings.tenantAppDefs as AppAccelAppDef[] : [];
        delete settings.tenantAppDefs;          // No reasons to keep to almost identical copies
        apps = apps.sort((a,b) => { return ((a.display_name as string) > (b.display_name as string)) ? 1 : -1; })
        data.decryption_ca_cert_name = '';
        if (settings.enabled) {
            data.decryption_ca_cert_name = (settings.decryption_ca_cert_name !== undefined) ? settings.decryption_ca_cert_name : "";
        }

        if (savedToZos) {
            data.appAccelEnabled = settings.enabled;
        }
        const featureEnabled = settings.enabled;
        data.appAccelSettings.enabled = featureEnabled;
        data.appAccelSettings.ademEnabled = settings.ademEnabled;
        data.appAccelSettings.ademPercentIndex = settings.ademPercentIndex;

        const decryption_ca_cert_name = settings.decryption_ca_cert_name;
        data.appAccelSettings.decryption_ca_cert_name = decryption_ca_cert_name;

        // We have to trust that the cert they specified is good.
        if (data.isPanoramaManaged) {
            if  (decryption_ca_cert_name.length > 0) {
                data.globalCertState = CertState.NORMAL;
                data.selectedCertName = decryption_ca_cert_name;
            } else {
                data.globalCertState = CertState.NO_CERT;
                data.selectedCertName = '';
            }
        } 
        data.decryption_ca_cert_name = decryption_ca_cert_name;
        
        data.appAccelSettings.tenantApps = [];
        apps.forEach((appDef: AppAccelAppDef) => {
            const appUiDef = buildAppState(data, appDef);
            data.appAccelSettings.tenantApps.push(appUiDef);
        })

        logState(data, `setAcclSettings`);
        setNaaGlobalData( {...data} );
    }

    const setAppInfo = (index: number): void => {
        let data: NaaGlobalData | undefined = contextSingleton.getInstance();

        if (index >= 0) {
            const appInfo = data.appAccelSettings.tenantApps[index];
            appInfo.appState = appInfo.enabled ? AppState.app_enabled : AppState.app_disabled;
            data.appAccelSettings.tenantApps[index] = appInfo;
        } else if (index === -1) {
            const tenantApps = data.appAccelSettings.tenantApps;
            for (let i=0, len=tenantApps.length; i < len; i++) {
                tenantApps[i].appState = AppState.disabled;
            }
        } else if (index === -2) {
            const tenantApps = data.appAccelSettings.tenantApps;
            for (let i=0, len=tenantApps.length; i < len; i++) {
                const app: AppAccelUiAppDef= buildAppState(data, tenantApps[i]);
                tenantApps[i].appState = app.appState;
            }
        }

        setNaaGlobalData( {...data} );
    }

    const setInitialized = (initialized: CfgInitState): void => {
        let data = contextSingleton.getInstance();
        data.uiInitialized = initialized;

        logState(data, `setInitialized`);
        setNaaGlobalData( {...data} );
    }

    const setAuthToken = (token: string): void => {
        let data = contextSingleton.getInstance();
        data.authToken = token;
        logState(data, `setAuthToken`);
        setNaaGlobalData( {...data} );
    }

    const setError = (msg: MessageDescriptor | undefined, 
                      errorCode?: number | undefined, phase2Code?: number | string | undefined): void => {
        let data = contextSingleton.getInstance();
 
        let err = undefined;
        if (msg) {
            err =  {...msg};

            if (phase2Code) {
                err.defaultMessage = `${msg?.defaultMessage} Error Code (${phase2Code})`;
                data.phase2Error = err;
            } else {
                if (errorCode ) {
                    err.defaultMessage = `${msg?.defaultMessage} Error Code (${errorCode})`;
                }
                data.error = err;
            }
        }
        // logState(data, `setError`);
        setNaaGlobalData( {...data} );
    }

    const setCurrentTenantInfo = (tsgId: string, subTenId: string | undefined): void => {
        let data = contextSingleton.getInstance();
        data.currentTsgId = tsgId;
        data.currentSubTenId = subTenId;

        logState(data, `setCurrentTenantInfo`);
        setNaaGlobalData( {...data} );
    }

    const setToastData = (toastStyle: ToastAppearance | undefined, toastMsg: string | undefined, 
                          errorCode: number | string | undefined ) => {
        let data = contextSingleton.getInstance();
        data.toastStyle = toastStyle;
        let msg = toastMsg;
        if (errorCode !== undefined) {
            msg = `${msg} Error Code (${errorCode})`;
        }
        data.toastMsg = msg;
        logState(data, `setToastData`);
        setNaaGlobalData( {...data} );
    }

    const setAdemAvail = (avail: CfgInitState) => {
        let data = contextSingleton.getInstance();
        data.ademAvail = avail;
        logState(data, `setAdemAvail`);
        setNaaGlobalData( {...data} );
    }

    const setAdemData = (enabled: boolean, idx: number) => {
        let data = contextSingleton.getInstance();
        data.appAccelSettings.ademEnabled = enabled;
        data.appAccelSettings.ademPercentIndex = idx;
        // data.ademBackwdCompat = !!backwardCompat;
        logState(data, `setAdemData`);
        setNaaGlobalData( {...data} );
    }

    const setLicenseInfo = (appAcclLicenseInfo: Addon_licenses | undefined, ademLicenseInfo?: ADEMLicenseInfo | undefined) => {
        let data = contextSingleton.getInstance();
        data.ademLicenseInfo = ademLicenseInfo;
        data.appAcclLicenseInfo = appAcclLicenseInfo === undefined ? data.appAcclLicenseInfo : appAcclLicenseInfo;
        logState(data, `setAdemLicenseInfo`);
        setNaaGlobalData( {...data} );
    }

    const showPopup = (popupType: PopupTypes) => {
        let data = contextSingleton.getInstance();
        data.currentPopup = popupType;
        logState(data, `showPopup`);
        setNaaGlobalData( {...data} );
    }

    const resetContext = (): void => {
        let data: NaaGlobalData | undefined = contextSingleton.getInstance();

        data = undefined;
        data = contextSingleton.getInstance();

        setNaaGlobalData( {...data} );
    }

    const setFeatureEnabled = (enabled: boolean): void => {
        let data: NaaGlobalData | undefined = contextSingleton.getInstance();
        if (enabled === data.appAccelEnabled) { return }

        data.appAccelEnabled = enabled;
        const oldApps = data.appAccelSettings.tenantApps;

        // When we disable app accel we want all apps to be disabled (enabled === false) and
        // the appState set to 'disabled'.  This will mean the toggle is disabled.  
        // 
        // When we enable app accel we still want the apps to be disabled but we want the 
        // appState to be 'app_disabled' which mean the user can enable/disable the app.
        const appStatus: AppState = (enabled) ? AppState.app_enabled : AppState.disabled;
        const apps = data.appAccelSettings.tenantApps;
        apps.forEach((app: AppAccelUiAppDef) => {
            app.appState = appStatus;
            app.enabled = false;
        });

        // if we just enabled, the cert name is ''.  If we disabled app accel, the cert name = '';
        data.decryption_ca_cert_name = '';
        data.selectedCertIdx = -1;

        setNaaGlobalData( {...data} );
    }

    const setCertName = (certName: string | undefined, savedToZos?: boolean): void => {
        let data: NaaGlobalData | undefined = contextSingleton.getInstance();

        const restoreCert = (certName === undefined);
        certName = (restoreCert) ? data.decryption_ca_cert_name : certName;
        const newCertName = certName ? certName : '';

        if (data.isPanoramaManaged) {
            data.globalCertState = newCertName.length > 0 ? CertState.NORMAL : CertState.NO_CERT;
        } else {
            const certIndex = data.tenantCerts.findIndex((certInfo) => {return certInfo["@name"] === certName})

            const noCertYet = data.decryption_ca_cert_name?.length > 0 ? false : true;
            if (noCertYet && certIndex >= 0 && savedToZos) {
                resetAppStates(data.appAccelSettings.tenantApps, true);
                if (certIndex >= 0) {
                    data.globalCertState = data.tenantCerts[certIndex].certState;
                }
            }

            data.selectedCertIdx = certIndex;
        }
        
        data.selectedCertName = newCertName;
        if (savedToZos) {
            data.decryption_ca_cert_name = newCertName;
        }

        setNaaGlobalData( {...data} );
        if (data.isPanoramaManaged && savedToZos) {
            if (data.globalCertState === CertState.NO_CERT) {
                setAppInfo(AllAppModifier.disableAll);                 // no cert selected, disable all apps.
            } else {
                setAppInfo(AllAppModifier.resetState)
            }
        }
    }

    // this is the master list from Fawkes
    const setCertInfoList = (certInfoList: FawkesCertInfo[] | undefined): void => {
        let data: NaaGlobalData | undefined = contextSingleton.getInstance();

        let currentCertIdx = -1;
        let globalCertState = CertState.NO_STATE;
        let newCertList: FawkesCertInfoUI[] = [];
        const certName = data.appAccelSettings.decryption_ca_cert_name
        let selectedCertName = '';

        if (certInfoList !== undefined) {
            const now = new Date().getTime();
            const nowPlus30 = now + ((1000 * 3600 * 24) * 30);

            certInfoList = certInfoList.sort((a, b) => { return (a["@name"] > b["@name"]) ? 1 : -1 })
            
            for (let i=0, len=certInfoList.length; i < len; i++) {
                const cert = certInfoList[i]

                // ignore the cert if it is not valid yet
                const notBefore = new Date(cert["not-valid-before"]).getTime();
                if (notBefore > now) {
                    continue;
                }

                // ignore the cert if it is expired but not the current cert
                const expired = new Date(cert["not-valid-after"]).getTime();
                if (expired < now && cert['@name'] !== certName) {
                    continue;
                }

                // select the cert if it is a CA and it has a private key;
                if ((cert["ca"] === "yes") && (cert["private-key"] !== undefined)) {
                    const newCert: FawkesCertInfoUI = {...(cert as FawkesCertInfoUI)};
                    newCert.certState =  (nowPlus30 > expired) ? CertState.EXPIRING : CertState.NORMAL;
                    newCert.certState = (now > expired) ? CertState.EXPIRED_CERT : newCert.certState;
                    newCertList.push(newCert);

                    // save the index if this is the current cert for the tenant
                    if (cert['@name'] === certName) {
                        currentCertIdx = newCertList.length - 1;
                        selectedCertName = certName;
                    }
                }
            }

            if (currentCertIdx === -1) {
                globalCertState = CertState.NO_CERT
            } else {
                globalCertState = newCertList[currentCertIdx].certState;
            }
        } else {
            data.globalCertState = (data.decryption_ca_cert_name.length > 0) ? CertState.CERT_SELECTED_NO_CERT_LIST : CertState.NO_STATE;
        }

        data.globalCertState = globalCertState;
        data.selectedCertIdx = currentCertIdx;
        data.selectedCertName = selectedCertName
        data.tenantCerts = newCertList;
        setNaaGlobalData( {...data} );

        if (globalCertState !== CertState.NORMAL && globalCertState !== CertState.EXPIRING) {
            setAppInfo(AllAppModifier.disableAll);                 // no cert selected, disable all apps.
        } else {
            setAppInfo(AllAppModifier.resetState)
        }
    }

    const setPanoramaManaged = (isPanoramaManaged: boolean): void => {
        let data: NaaGlobalData | undefined = contextSingleton.getInstance();
        data.isPanoramaManaged = isPanoramaManaged;
        Logger.info(`tenant is a panorama managed tenant: ${isPanoramaManaged}`)

        setNaaGlobalData( {...data} );
    }

    const setSaveCertNow = (saveCertNow: boolean): void => {
        let data: NaaGlobalData | undefined = contextSingleton.getInstance();
        if (data.saveCertInfoNow !== saveCertNow) {
            data.saveCertInfoNow = saveCertNow;
            setNaaGlobalData( {...data} );
        }
    }

    const myContext: NaaContextType = {
        naaGlobal: globalNaaData,
        setSystemCapable: setSysCapable,
        setAcclSettings, 
        setInitialized,
        setAuthToken,
        setError,
        setCurrentTenantInfo,
        setToastData,
        showPopup,
        resetContext,
        setAdemAvail,
        setAdemData,
        setLicenseInfo,
        setAppInfo,
        setFeatureEnabled,
        setCertName,
        setCertInfoList,
        setPanoramaManaged,
        setSaveCertNow,
    }
    
    return <NaaGlobalContext.Provider value={myContext}>{children}</NaaGlobalContext.Provider>
}

export default NaaContextProvider;