import { getState, getAuthState, Entitlements } from '@sparky/framework';

import { AppAccelAddonLicense, AddOnInfo, ZRestResponse, FetchStatus } from '../data/queryResultsDefinition';
import { Addon_licenses } from './AppAccelContext'

import { appAccelAppID } from './constants';
import { ZGet } from './backend'
import NaaURLs from './urls';
import Logger from './Logger';

import {rnOnlyLicenseTypes} from './constants'
import { LicenseInfoBase, LicEntitlements, SparkyInstanceInfo } from '../data/sparkyDefs';
import { TsgInstances } from '@sparky/framework/build/types/framework/indexedArrays';

const LicenseSingleton = (() => {
    let naaLicense: Addon_licenses | undefined = undefined;
    return {
        getInstance: (): Addon_licenses => {
            if (naaLicense === undefined) {
                naaLicense = {items: [], includeAdem: false, in_grace_period: false, urlEnvModifier: ''}
            }

            return naaLicense;
        }
    }
})()

export const getAACLicenseArray = (): Addon_licenses => {
    return LicenseSingleton.getInstance()
}

export async function hasLicense(): Promise<Boolean> {
    let bHasLicense = false;
    let in_grace_period = true;

    const bHasFawkesLicense = await oldHasLicense();
    if (bHasFawkesLicense) {
        return bHasFawkesLicense;
    }
    // return bHasLicense;

    // These modifiers are used for building our URLs.  Based on environment
    // the modifier changes.  We have special ones for staging1 and staging2. 
    // All other environments can use the naaUrlModifier.
    const env: string[] = [
        NaaURLs.naaUrlModifier,
        NaaURLs.naaUrlModifierStg1,
        NaaURLs.naaUrlModifierStg2,
    ];
    
    const licInfo: Addon_licenses = {items: [], includeAdem: false, in_grace_period: true, urlEnvModifier: ''};
    try {
        let response: ZRestResponse | undefined = undefined;
        let err: FetchStatus | undefined = undefined;

        // const licInfo = LicenseSingleton.getInstance();
        for (let i=0, len=env.length; i < len; i++) {
            try {
                let url = env[i] + NaaURLs.serverNaaGetTenantAddonLicenses;
                const queryParams = (i !== 0) ? `?retry=${i}` : '';
                url = url + queryParams;
                response = await ZGet(url, '');
                if (!(response as FetchStatus)?.status?.ok) { throw response }

                licInfo.urlEnvModifier = env[i]
                break;
            }
            catch(e: any) {
                err = e as FetchStatus;
                if (err.status.http_status === 404) {
                    continue;
                }
                throw e;
            }
        }

        if (! response) { throw err}
        const addonLicenses = response as AppAccelAddonLicense;
        if (addonLicenses?.items.length !== 0) {
            bHasLicense = true;

            // save the tenant's licenses
            licInfo.items = addonLicenses.items;

            // check for RN License
            let includeAdem = false;
            addonLicenses.items.forEach((item: AddOnInfo) => {
                const foundRn = rnOnlyLicenseTypes.findIndex((licType) => { return item.license_type === licType })
                includeAdem = includeAdem || foundRn === -1;  
                in_grace_period = in_grace_period && !!(item?.in_grace_period);
            });
            licInfo.includeAdem = includeAdem
            licInfo.in_grace_period = in_grace_period;
        }
    }
    catch(e: any) {
        bHasLicense = false;
        const licInfo = LicenseSingleton.getInstance();
        licInfo.items = [];
        licInfo.includeAdem = false;
        licInfo.in_grace_period = false;
    }

    if (bHasFawkesLicense !== bHasLicense) {
        // alert('license mismatch')
        if (bHasLicense) {
            const realLicInfo = LicenseSingleton.getInstance();
            realLicInfo.in_grace_period = licInfo.in_grace_period;
            realLicInfo.includeAdem = licInfo.includeAdem;
            realLicInfo.items = licInfo.items;
            realLicInfo.urlEnvModifier = licInfo.urlEnvModifier;
        }
    }
    return bHasLicense;
}

export async function oldHasLicense() {
    let in_grace_period = true;
    let includeAdem = false;
    let validLicense = false;

    // licInfo is local storage for license info.  We don't have context yet so we save it locally.  When we make
    // the capabilities call in AppAccelRoutes (only done once), we get licInfo and store it in context.
    const licInfo = LicenseSingleton.getInstance();
    const authState = getAuthState();

    const instances:  Readonly<TsgInstances> = authState?.instances
    if (instances?.length > 0) {
        for (let inst=0, instLen=instances.length; inst < instLen; inst++) {
            const appId = instances[inst]?.app_id;
            // only check license for prisma access app_ids
            if (appId !== 'prisma_access_edition' && appId !== 'prisma_access_edition_onprem' && 
                appId !== 'prisma_access_panorama' ) { continue; }

            const entitlements: LicenseInfoBase[] = instances?.get(appId)?.entitlements;

            if (entitlements) {
                licInfo.items.length = 0;
                const items: AddOnInfo[] = [];

                for (let i=0, len=entitlements.length; i < len; i++) {
                    const lic = entitlements[i];
                    Logger.info(`license from sparky '${lic.app_id}'`)
                    if (lic.app_id === appAccelAppID) {
                        validLicense = true;
                        const expiration = lic.license_expiration as string;
                        const in_grace = (new Date().getTime()) > (new Date(expiration).getTime());
                        const item: AddOnInfo = {license_type: lic.license_type, license_expiration: expiration, in_grace_period: in_grace}
                        items.push(item)

                        const foundRn = rnOnlyLicenseTypes.findIndex((licType) => { return lic.license_type === licType })
                        includeAdem = includeAdem || foundRn === -1;  
                        in_grace_period = in_grace_period && !!(item?.in_grace_period);
                    }

                    licInfo.items = items;
                    licInfo.includeAdem = includeAdem
                    licInfo.in_grace_period = in_grace_period;
                }

                if (licInfo.items.length > 0) {
                    Logger.info(`found App_Accel license, ${appId}`);
                    validLicense = true;
                    break;
                }
            }
            if (licInfo.items.length > 0) {
                break;
            }
        }
    } else {
        validLicense = false;
        const licInfo = LicenseSingleton.getInstance();
        licInfo.items = [];
        licInfo.includeAdem = false;
        licInfo.in_grace_period = false;
    }
    Logger.info(`APP_ACCEL has license = ${validLicense}`);

    return validLicense;
}