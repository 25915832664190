import { getFrameworkServiceUrl, AuthState, fawkesUtils, getMicroAppVars } from '@sparky/framework'

import { SparkyDem } from '../data/sparkyDefs'
import Logger from './Logger';
import { Products } from './backend';
import { AppAccelSettingsCommon, FawkesCertInfo } from '../data/queryResultsDefinition';
import { AppAccelSettings } from './AppAccelContext'
import { SelectItem } from '@panwds/react-ui';
import { getStrings } from '../l18n/intlTools';

// import { appAccelMaxPorts } from './constants';
const strs = getStrings();

const Gateways = {
    'api_gateway': 'api_gateway',
    'api_gateway_global_service': 'api_gateway_global_service',
    'api_gateway_gov': 'api_gateway_gov'
  } 

export const ademPercentToIdx = (percent: number): number => {
    let idx = 0;
    if (percent !== undefined && typeof(percent) === 'number') {
        percent = percent >= 5 ? percent : 5;
        percent = percent <= 20 ? percent : 20;
        idx = (percent - 5)/5; 
    }
    return idx;
}

export const ademPercentIdxToPercent = (idx:number): number => {
    const percent = (idx + 1) * 5;
    return percent;
}

export const getADEMDomain = ( authState: AuthState, component: Products): string | undefined =>  {
    let domain: string | undefined = '';
    if (component === Products.ADEM_LICENSE) {
        const demInfo: SparkyDem | undefined = getMicroAppVars('dem') ?? {};
        domain = demInfo.api_server_url ? 'https://' + demInfo.api_server_url : undefined;
    } else {
        domain = '';
    }

    return  domain;
}

export const getAppAcclDomain = ( authState: AuthState ): string | undefined => {
    let domain: string | undefined = '';

    let region = authState.instances.getPlatformRegion();
    region = region ?? Gateways.api_gateway;
    region = (region.indexOf('gov') === -1) ? Gateways.api_gateway : Gateways.api_gateway_gov;

    domain = getFrameworkServiceUrl(region);
    return domain;
}


export const getFawkesDomain = ( authState: AuthState ): string | undefined => {
    const domain = getFrameworkServiceUrl(Gateways.api_gateway);
    return domain;
  }
  
  export const getFawkesCertsDomain = ( authState: AuthState): string | undefined => {
    return fawkesUtils.getRedirectURL();
  }

//-----
// utility to determine the number of days between 2 dates:
export const diffBetweenDates = (date1: Date, date2: Date): number => {
    const differenceInTime = date2.getTime() - date1.getTime();
    const differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));

    return differenceInDays;
}

//-----
// utility function for port validation
const validatePort = (inPort: string): boolean => {
    const port = inPort.trim();
    const chkSpaces = port.indexOf(' ');
    if (chkSpaces !== -1) {
        return false;
    }
    const ports = port.split('-');
    let valid = true;

    const validatePort = (p: string): boolean => {
        const nPort = Number(p);
        if (isNaN(nPort)) {
            return false;
        }
        if (! Number.isInteger(nPort)) {
            return false;
        }
        if (nPort === 0 || nPort > 65535 || nPort === 60443) {
            return false;
        }

        return true;
    }

    valid = valid && validatePort(ports[0]);
    if (ports.length > 1) {
        valid = valid && validatePort(ports[1]);
    }

    return valid;
}

//----
// utility function for validate a string or comma separated ports.
export const validatePortString = (pStr: string): boolean => {
    let valid = true;

    if (pStr.length === 0) {
        return true;
    }
    const ports = pStr.split(',');

    // if (ports.length > appAccelMaxPorts) {
    //     return false;
    // }

    const portTest: Record<string,string>= {}
    for (let i=0, len=ports.length; i < len; i++) {
        valid = valid && validatePort(ports[i]);
        if (!valid) { break; }
        const port = ports[i].trim()
        if (portTest[port] !== undefined) {
            valid = false;
            break;
        }
        portTest[ports[i]] = ports[i]
    }

    return valid;
}

// export const settingsRespToSettings = ( respSettings: AppAccelSettingsCommon): AppAccelUISettings => {
//     const uiSettings: AppAccelUISettings = {} as AppAccelUISettings;

//     if (! respSettings) { return uiSettings }
//     uiSettings.enabled = respSettings.enabled;
//     uiSettings.ports = [];
//     let ports = respSettings.ports ?  [...respSettings.ports] : [];
    
//     const removePorts = (index: number): string[] => {
//         let newPorts = [];
//         if (index !== -1) {
//             for (let i=0, len=ports.length; i < len; i++) {
//                 if (i !== idx) { newPorts.push(ports[i])}
//             }
//         } else {
//             newPorts = ports;
//         }
//         return newPorts;
//     }

//     let idx = ports.findIndex((port) => { return port === '443'});
//     uiSettings.https_encryption = (idx !== -1);
//     ports = removePorts(idx)
  
//     idx = ports.findIndex((port) => { return port === '445'});
//     uiSettings.smb_encryption = (idx !== -1);
//     ports = removePorts(idx)

//     idx = ports.findIndex((port) => { return port === '22'});
//     uiSettings.ssh_encryption = (idx !== -1);
//     ports = removePorts(idx)

//     uiSettings.port_string = ports.toString();
//     uiSettings.port_encryption = uiSettings.port_string.length > 0;
//     return uiSettings;
// }

export const settingsToSettingsBody = (settings: AppAccelSettings): AppAccelSettingsCommon => {
    const resp: AppAccelSettingsCommon = {} as AppAccelSettingsCommon;
    resp.enabled = settings.enabled;
    const ports: string[] | undefined = settings.ports ? settings.ports : [];
    
    if (ports?.length === 0) {       // must have at least one entry
        ports.push( "1-65535");
    }

    // this code is left over from when when had port selection UI.
    // ---------------
    // let ports = settings.port_string.trim() .split(',');
    // const tempSettings = settingsRespToSettings({enabled: resp.enabled, ports: [...ports]})
    
    // let port_string = tempSettings.port_string;
    // ports = port_string.length > 0 ? port_string.split(',') : [];

    // settings.https_encryption = settings.https_encryption || tempSettings.https_encryption;
    // settings.smb_encryption = settings.smb_encryption || tempSettings.smb_encryption;
    // settings.ssh_encryption = settings.ssh_encryption || tempSettings.ssh_encryption;

    // resp.ports = settings.port_encryption ? ports : [];

    // if (settings.https_encryption) { resp.ports.push('443') }
    // if (settings.smb_encryption) { resp.ports.push('445') }
    // if (settings.ssh_encryption) { resp.ports.push('22') };

    // console.log(`ports: ${JSON.stringify(ports,null, 2)}`)
    resp.ports = ports as string[];
    return resp;
}
